import Cookies from 'js-cookie'
import $ from 'jquery'

const integrations = {
  loadAll: () => {
    const Confetti = window.Confetti
    const {
      googleAnalyticsId,
      hubspot,
      intercomAppId,
      linkedInPartnerId,
      facebookPixel,
      googleTagManagerId,
      googleAnalyticsMeasurementId,
    } = Confetti
    if (googleAnalyticsId) integrations.load.googleAnalytics(googleAnalyticsId)
    if (googleAnalyticsMeasurementId) integrations.load.googleAnalytics4(googleAnalyticsMeasurementId)
    if (hubspot) integrations.load.hubspot(hubspot)
    if (intercomAppId) integrations.load.intercom(intercomAppId)
    if (linkedInPartnerId) integrations.load.linkedin(linkedInPartnerId)
    if (facebookPixel) integrations.load.facebook(facebookPixel)
    if (googleTagManagerId) integrations.load.googleTagManager(googleTagManagerId)

    Confetti.tracker.initComplete()
  },
  load: {
    googleAnalytics: (googleAnalyticsId) => {
      !(function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r
        ;(i[r] =
          i[r] ||
          function () {
            !(i[r].q = i[r].q || []).push(arguments)
          }),
          (i[r].l = 1 * new Date())
        ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
        a.async = 1
        a.src = g
        m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

      ga('create', googleAnalyticsId, {
        cookieDomain: document.location.hostname,
        cookieName: '_gacust',
      })
    },
    googleAnalytics4: (googleAnalyticsMeasurementId) => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }

      window.gtag = gtag

      var s = document.getElementsByTagName('script')[0]
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.onload = function () {
        gtag('js', new Date())
        gtag('config', googleAnalyticsMeasurementId)
      }
      script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsMeasurementId}`
      s.parentNode.insertBefore(script, s)
    },
    hubspot: (hubspot) => {
      const run = function (d, s, i, r) {
        if (d.getElementById(i)) {
          return
        }
        var n = d.createElement(s),
          e = d.getElementsByTagName(s)[0]
        n.id = i
        n.src = 'https://js.hs-analytics.net/analytics/' + Math.ceil(new Date() / r) * r + '/' + hubspot + '.js'
        e.parentNode.insertBefore(n, e)
      }
      run(document, 'script', 'hs-analytics', 300000)
    },
    intercom: (intercomAppId) => {
      if (!window.isInIframe()) {
        const intercomSettings = {
          app_id: intercomAppId,
          api_base: 'https://api-iam.intercom.io',
        }
        window.intercomSettings = intercomSettings
        ;(function () {
          var w = window
          var ic = w.Intercom
          if (typeof ic === 'function') {
            ic('reattach_activator')
            ic('update', intercomSettings)
          } else {
            var d = document
            var i = function () {
              i.c(arguments)
            }
            i.q = []
            i.c = function (args) {
              i.q.push(args)
            }
            w.Intercom = i
            const l = function () {
              var s = d.createElement('script')
              s.type = 'text/javascript'
              s.async = true
              s.src = 'https://widget.intercom.io/widget/' + intercomAppId
              var x = d.getElementsByTagName('script')[0]
              x.parentNode.insertBefore(s, x)
            }
            if (w.attachEvent) {
              w.attachEvent('onload', l)
            } else {
              w.addEventListener('load', l, false)
            }
          }
        })()
      }
    },
    linkedin: (linkedInPartnerId) => {
      window._linkedin_data_partner_id = linkedInPartnerId
      !(function () {
        var s = document.getElementsByTagName('script')[0]
        var b = document.createElement('script')
        b.type = 'text/javascript'
        b.async = true
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
        s.parentNode.insertBefore(b, s)
      })()
    },
    facebook: (facebookPixel) => {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
      fbq('init', facebookPixel)
    },
    googleTagManager: (googleTagManagerId) => {
      !(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', googleTagManagerId)
    },
  },
}

$(() => {
  const Confetti = window.Confetti
  if (Cookies.get('confetti_cookies') === 'declined') return

  $(() => {
    const $cookieBanner = $('.cookie-banner')
    const { showCookieBanner, isPreview, isPaymentDomain } = Confetti

    if (isPreview || isPaymentDomain) {
      $cookieBanner.remove()
      return
    }
    if (showCookieBanner) {
      if (window.isInIframe()) {
        $cookieBanner.remove()
        return
      }
      $cookieBanner.find('.cookie-banner__accept').on('click', () => {
        Cookies.set('confetti_cookies', 'accepted')
        integrations.loadAll()
        $cookieBanner.remove()
      })
      $cookieBanner.find('.cookie-banner__decline').on('click', () => {
        Cookies.set('confetti_cookies', 'declined')
        $cookieBanner.remove()
      })
    } else {
      if (Cookies.get('confetti_cookies') !== 'declined') {
        integrations.loadAll()
      }
    }
  })
})
