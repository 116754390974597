import $ from 'jquery'

import calendar from './icons/calendar.svg'
import location from './icons/location.svg'
import clock from './icons/clock.svg'

const icons = {
  calendar,
  location,
  clock,
}

export default function () {
  $('.schedule-icon[data-icon]').each((i, icon) => {
    if (icon.hasChildNodes()) return
    const $icon = $(icon)
    $icon.append(icons[$icon.data('icon')])
  })
}
