import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

import { getParameterByName } from './utils'
window.getParameterByName = getParameterByName

import loadBlocks from './load-blocks'

loadBlocks()

import './disable-double-submit'
import './tracker'
import './fade-in-scroll'
import './in-iframe'
import './archived'
import './cookies'
import './textarea-auto-expand'

import '../lib/decimal-round'

import * as Sentry from '@sentry/browser'
window.Sentry = Sentry
import { Dedupe as DedupeIntegration } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/browser'

if (window.Confetti && ['production', 'staging'].includes(window.Confetti.env)) {
  Sentry.init({
    dsn: 'https://9f6a46291dc94c75ab74de73fd15ec16@o17965.ingest.sentry.io/34838',
    environment: window.Confetti.env,
    integrations: [new BrowserTracing(), new DedupeIntegration()],
    ignoreErrors: [
      // Error generated by a bug in auto-fill library from browser
      // https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,
    ],

    tracesSampleRate: 0.03,
  })
}
