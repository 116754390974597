// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'

export default function () {
  $('.header-title').each(function (i, title) {
    const $title = $(title)
    const text = $title.text()
    const { length } = text
    const className = length > 55 ? 'xlong' : length > 25 ? 'long' : length > 15 ? 'medium' : 'short'
    $title.addClass('header-title-' + className)
    return $title.removeClass('header-title-loading')
  })
}
