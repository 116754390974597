import dayjs from '../../app/utils/dayjs'
import $ from 'jquery'

export default function () {
  const descriptions = document.querySelectorAll(
    '.speakers-section.focus-on-speaker .schedule-item__description, .schedule-section .schedule-item__description'
  )

  const isSafariOrIOS = /^((?!chrome|android).)*(safari|iphone|ipad|ipod)/i.test(navigator.userAgent)

  descriptions.forEach((itemDescription) => {
    const lineHeight = parseFloat(window.getComputedStyle(itemDescription, null).getPropertyValue('line-height'))
    const lines = itemDescription.offsetHeight / lineHeight
    const shade = itemDescription.querySelector('.schedule-item__description-shade')

    if (lines > 7) {
      if (isSafariOrIOS) {
        shade.style.display = 'block'
        itemDescription.style.maxHeight = '50px'
      } else {
        itemDescription.classList.add('schedule-item__read-more')
        itemDescription.style.maxHeight = itemDescription.offsetHeight + 'px'
      }
      const readMore = itemDescription.parentNode.querySelector('.schedule-item__read-more-button')
      readMore.classList.add('schedule-item__read-more-button--visible')
      readMore.addEventListener('click', () => {
        readMore.classList.remove('schedule-item__read-more-button--visible')
        itemDescription.style.maxHeight = 2000 + 'px'
        itemDescription.classList.add('schedule-item__description--full')
        shade.style.display = 'none'
      })
    }
  })

  $(() =>
    $('.schedule-section').each(function () {
      const now = new Date()
      const $schedule = $(this)
      let start = $schedule.data('schedule-start')
      let end = $schedule.data('schedule-end')
      if (!start || !end) {
        return
      }

      start = dayjs(start, 'YYYY-MM-DDTHH:mm:ss')
      end = dayjs(end, 'YYYY-MM-DDTHH:mm:ss')
      if (!(dayjs(now).isAfter(start) && dayjs(now).isBefore(end))) {
        return
      }

      const getTime = function ($el) {
        const time = $el.find('.schedule-time time').attr('datetime')
        return time
      }

      $schedule.find('.schedule-slot').each(function () {
        const $current = $(this)
        // const $prev = $current.prev()
        const $next = $current.next()
        const currentTime = getTime($current)
        // const prevTime = getTime($prev)
        const nextTime = getTime($next)
        if (dayjs(now).isAfter(currentTime) && dayjs(now).isBefore(nextTime)) {
          return $current.addClass('schedule-slot-current')
        } else if (dayjs(now).isAfter(currentTime) && dayjs(now).isAfter(nextTime)) {
          return $current.addClass('schedule-slot-past')
        }
      })

      return $schedule.addClass('schedule-loaded')
    })
  )
}
