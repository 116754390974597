/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Read more about facebook events
// https://www.facebook.com/business/help/402791146561655

const gaExists = () => !(typeof ga === 'undefined' || ga == null)
const ga4Exists = () => !(typeof gtag === 'undefined' || gtag == null)

const setGoogleAnalyticsPath = (path) => {
  if (gaExists()) ga('set', { page: path })
  if (ga4Exists()) gtag('set', { page_path: path })
}

const gp = function () {
  if (gaExists()) ga('send', 'pageview')
  if (ga4Exists()) gtag('event', 'page_view')
}

const ge = function (category, action, value) {
  if (gaExists()) {
    ga('send', {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventValue: value != null ? Math.round(value) : undefined,
    })
  }
  if (ga4Exists()) {
    gtag('event', action, {
      event_category: category,
      value,
    })
  }
}

const fb = function (name, opts) {
  if (typeof fbq !== 'undefined' && fbq !== null) {
    return fbq('track', name, opts)
  }
}

var tracker = {
  initiated: false,
  initComplete() {
    this.initiated = true
    if (this.path) {
      setGoogleAnalyticsPath(this.path)
    }
    return (() => {
      const result = []
      while (window.Confetti.trackerQueue.length) {
        const name = window.Confetti.trackerQueue.shift()
        result.push(this.t(name))
      }
      return result
    })()
  },

  track(name) {
    if (name === 'pageview') {
      gp()
      return fb('PageView')
    } else if (name === 'rsvp:show') {
      ge('rsvp', 'show')
      return fb('Lead')
    } else if (name === 'rsvp:created') {
      ge('rsvp', 'created')
      return fb('CompleteRegistration')
    } else if (name === 'tickets:show') {
      ge('tickets', 'show')
      return fb('Lead')
    } else if (name === 'tickets:initiated') {
      ge('tickets', 'initiated')
      return fb('InitiateCheckout')
    } else if (/^tickets:checkout/.test(name)) {
      let [category, action, currency, amount] = Array.from(name.split(':'))
      amount = parseFloat(amount)
      ge('tickets', 'checkout', amount)
      fb('Purchase', { value: amount, currency })
      return fb('CompleteRegistration')
    } else {
      throw new Error('Undefined event: ' + name)
    }
  },
  setPath(path) {
    if (this.initiated) {
      setGoogleAnalyticsPath(path)
    } else {
      return (this.path = path)
    }
  },

  t(name) {
    if (this.initiated) {
      return tracker.track(name)
    } else {
      return window.Confetti.trackerQueue.push(name)
    }
  },
}

if (!window.Confetti) {
  window.Confetti = {}
}
window.Confetti.tracker = tracker

// https://stackoverflow.com/a/1842760/361337
// Med modifikation för att search innehåller '?' i början
const removeTrackQueryParameter = (function () {
  const { pathname } = window.location
  const { hash } = window.location
  let { search } = window.location
  if (search && search.length > 0) {
    search = search.replace(/&?tname(=[^&]*)?|^tname(=[^&]*)?&?/, '')
    return window.history.replaceState({}, document.title, pathname + search + hash)
  }
})()
