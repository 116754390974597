// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'

$('.index-view .archived').each(function () {
  const $a = $(this)
  const $doc = $(document)
  const $body = $('body')
  const $box = $a.find('.archived-box')
  const $hide = $('.archived-box-hide')

  const removeBox = function () {
    $body.removeClass('archived-visible')
    $a.addClass('hidden')
    return setTimeout(() => $a.remove(), 750)
  }

  $doc.one('scroll', function () {
    $a.addClass('loaded')
    return setTimeout(() => $body.addClass('archived-visible'), 500)
  })
  $box.on('click', function (e) {
    e.stopPropagation()
    return false
  })
  $hide.on('click', () => removeBox())
  return $doc.on('keyup', function (evt) {
    if (evt.keyCode === 27) {
      return removeBox()
    }
  })
})
