import $ from 'jquery'

import facebook from './icons/facebook.svg'
import instagram from './icons/instagram.svg'
import linkedin from './icons/linkedin.svg'
import twitter from './icons/twitter.svg'
import link from './icons/link.svg'
import youtube from './icons/youtube.svg'
import twitch from './icons/twitch.svg'
import tiktok from './icons/tiktok.svg'

const icons = {
  facebook,
  instagram,
  linkedin,
  twitter,
  link,
  youtube,
  twitch,
  tiktok,
}

export default function () {
  $('.social-buttons [data-icon]').each((i, icon) => {
    if (icon.hasChildNodes()) return
    const $icon = $(icon)
    $icon.append(icons[$icon.data('icon')])
  })
}
