// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'
import { getParameterByName } from './utils'

export default function () {
  $(() => {
    $('[data-image]').each(function (i, elem) {
      const $elem = $(elem)
      let url = $elem.attr('data-image')
      const size = Math.max($elem.width(), $elem.height())

      if (getParameterByName('sitePreview') === 'true' && $elem.attr('data-image-preview')) {
        url = $elem.attr('data-image-preview')
      } else if (size > 1000 && $elem.attr('data-image-3000')) {
        url = $elem.attr('data-image-3000')
      } else if (size > 500 && $elem.attr('data-image-2000')) {
        url = $elem.attr('data-image-2000')
      } else if (size > 250 && $elem.attr('data-image-1000')) {
        url = $elem.attr('data-image-1000')
      } else if (url === undefined || url.length <= 0) {
        return
      }
      $elem.addClass('image-loading')

      const $img = $('<img/>')
      const loaded = function () {
        $img.css({ position: 'absolute', left: '-100000px' })
        $(document.body).append($img)
        $elem.css('background-image', 'url(' + url + ')')
        $elem.removeClass('image-loading')
        $elem.addClass('image-ready')
        $elem.data('image-width', $img.width())
        $elem.data('image-height', $img.height())
        $img.remove()
      }

      $img.attr('src', url).on('load', loaded)
      setTimeout(loaded, 3000)
      //Falback in case the load callback isn't triggered
    })
  })
}
