/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default function () {
  $('[data-name=mailinglist__form]').each(function () {
    const $me = $(this)
    const $btn = $me.find('button')
    const $email = $me.find('input[name="contact[email]"]')

    const classNamespace = $me.data('class-namespace')

    const defaultText = $btn.data('copy-default')
    const failedText = $btn.data('copy-failed')
    const successText = $btn.data('copy-success')

    const loadingClass = `${classNamespace}__form--loading`
    var failedClass = failedClass
    var successClass = successClass

    $btn.prop('disabled', true)
    const validateEmail = function () {
      const email = $email.val()
      if (email.length > 1 && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        return $btn.prop('disabled', false)
      } else {
        return $btn.prop('disabled', true)
      }
    }

    $email.on('keyup', () => validateEmail())
    $email.on('change', () => validateEmail())

    const getTextWidth = function (text) {
      const $div = $(`<div style='position: absolute; display:block; left:-100000px;'>${text}</div>`)
      $btn.append($div)
      const width = $div.width()
      $div.remove()
      return width
    }

    return $me.on('submit', function (e) {
      e.preventDefault()
      $me.addClass(loadingClass)
      $btn.width(getTextWidth(defaultText))
      let categoryIds = []
      $me.find('input[name="contact[selectedCategories]"]:checked').each(function () {
        categoryIds.push($(this).val())
      })
      const data = {
        _csrf: $me.find('[name="_csrf"]').val(),
        ccc_email: $me.find('input[name=ccc_email]').val(),
        contact: {
          eventId: $me.find('input[name="contact[eventId]"]').val(),
          organisationId: $me.find('input[name="contact[organisationId]"]').val(),
          blockId: $me.find('input[name="contact[blockId]"]').val(),
          email: $email.val(),
          selectedPublicCategories: categoryIds || [],
        },
      }

      return $.post($me.attr('action'), data)
        .then(function () {
          $btn.width(getTextWidth(successText))
          $btn.text(successText)
          $btn.attr('disabled', 'disabled')
          $me.removeClass(loadingClass)
          $me.addClass(successClass)
          $email.hide()
          $me.find('input[name="contact[selectedCategories]"]:checked').each(function () {
            $(this).prop('checked', false)
          })
          return setTimeout(function () {
            $email.show()
            $me.removeClass(successClass)
            $btn.width(getTextWidth(defaultText))
            $btn.text(defaultText)
            return $email.val('')
          }, 3500)
        })
        .fail(function ({ responseJSON }) {
          $btn.removeClass('loading')
          $me.addClass(failedClass)
          return setTimeout(function () {
            $me.removeClass(failedClass)
            $btn.width(getTextWidth(defaultText))
            return $btn.text(defaultText)
          }, 3000)
        })
    })
  })
}
