export default function () {
  const elements = document.querySelectorAll('.image-attribution__name')
  const footerLinks = document.querySelector('.footer__links')
  if (elements.length) {
    const div = document.createElement('div')
    div.appendChild(document.createTextNode('Image attribution: '))
    div.classList.add('footer__attribution')
    document.querySelectorAll('.image-attribution__name').forEach((element) => {
      div.appendChild(element)
      div.appendChild(document.createTextNode(', '))
    })
    div.appendChild(document.createTextNode(' on '))
    div.appendChild(document.querySelector('.image-attribution__source'))
    footerLinks?.parentNode.insertBefore(div, footerLinks.nextSibling)
  }
}
