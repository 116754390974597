// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import getURLParameter from './get-url-parameter'
import $ from 'jquery'

const getAnchorTop = function () {
  const hash = window.location.hash || ''
  const match = hash.match(/--(\d+)$/)
  if (!match) {
    return
  }

  const id = parseInt(match[1])
  if (!id) {
    return
  }

  const $anchor = $('.block-' + id)
  if ($anchor.length > 0) {
    return $anchor.offset().top
  }
}

$(function () {
  if (getURLParameter('sitePreview')) {
    return
  }
  const $headerContent = $('.header-content')
  const $fadeIn = $('.fade-in-on-scroll')

  const handleHeader = function (scrollTop) {
    const headerContentTop = $headerContent.length > 0 ? $headerContent.position().top : 0
    const headerBottom = headerContentTop + $headerContent.height() * 0.75
    return $headerContent.toggleClass('fade-out', headerBottom < scrollTop)
  }

  const getDistances = function (scrollTop) {
    const height = $(window).height()
    if (scrollTop == null) {
      scrollTop = $(document).scrollTop()
    }
    const bottom = scrollTop + height * 0.85
    return { scrollTop, bottom }
  }

  const shouldShow = (elem, bottom) => bottom > $(elem).offset().top

  const fadeIn = function () {
    const { scrollTop, bottom } = getDistances()
    handleHeader(scrollTop)
    return $fadeIn.each(function (i, elem) {
      if (shouldShow(elem, bottom)) {
        return $(elem).removeClass('fade-out')
      }
    })
  }

  const scrollToAnchor = function (anchorTop) {
    if (anchorTop > 0) {
      let duration = 0.2 * Math.abs(anchorTop - $(document).scrollTop())
      duration = Math.max(200, duration)
      return $('html, body').animate({ scrollTop: anchorTop }, duration, 'swing')
    }
  }

  $(document).on('scroll', fadeIn)
  $(window).on('hashchange', function () {
    const anchorTop = getAnchorTop()
    return scrollToAnchor(anchorTop)
  })

  const anchorTop = getAnchorTop()
  const { bottom } = getDistances(anchorTop)

  $fadeIn.each(function (i, elem) {
    if (!shouldShow(elem, bottom)) {
      return $(elem).addClass('fade-out')
    }
  })

  return setTimeout(function () {
    scrollToAnchor(anchorTop)
    return fadeIn()
  }, 500)
})
