// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'

$('.disable-double-submit').on('submit', function (e) {
  const $form = $(this)
  if ($form.data('submitted') === true) {
    return e.preventDefault()
  } else {
    return $form.data('submitted', true)
  }
})
