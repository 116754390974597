export default function () {
  document.querySelectorAll('.countdown-content').forEach((element) => {
    let time = 0
    let fps = 2
    let interval = 1000 / fps
    const countdownToAttr = element.getAttribute('data-countdown-to')
    const countdownTo = new Date(countdownToAttr)
    let isActive = true

    let setCountdownValue = function (unit, value) {
      let item = element.querySelector(`.countdown-timer__item--${unit}`)
      let displayElement = item.querySelector('.countdown-timer__value')
      displayElement.innerHTML = value.toString().padStart(2, 0)
    }

    let updateTimer = function () {
      let timeLeft = countdownTo - Date.now()
      if (timeLeft < 0) {
        isActive = false
        return
      }
      let days = Math.floor(timeLeft / (24 * 60 * 60 * 1000))
      timeLeft -= days * 24 * 60 * 60 * 1000
      let hours = Math.floor(timeLeft / (60 * 60 * 1000))
      timeLeft -= hours * 60 * 60 * 1000
      let minutes = Math.floor(timeLeft / (60 * 1000))
      timeLeft -= minutes * 60 * 1000
      let seconds = Math.floor(timeLeft / 1000)
      timeLeft -= seconds * 1000
      //let millis = timeLeft

      setCountdownValue('days', days)
      setCountdownValue('hours', hours)
      setCountdownValue('minutes', minutes)
      setCountdownValue('seconds', seconds)
    }
    let animate = function (t) {
      if (!isActive) return
      //Cap FPS
      let dt = t - time
      if (dt > interval) {
        updateTimer()
        time = t
      }
      window.requestAnimationFrame(animate)
    }
    animate()
  })
}
