import speakers from './speakers'
import dataImage from './data-image'
import socialButtons from './social-buttons'
import handleLongTitles from './handle-long-titles'
import scheduleIcons from './schedule-icons'
import headerParallax from './header-parallax'
import headerVideo from './header-video'
import mailinglist from './mailinglist'

import blocksCountdown from './blocks/countdown'
import blocksSchedule from './blocks/schedule'
import blocksMenuOrganisation from './blocks/menu-organisation'
import imageAttribution from './image-attribution'

export default function () {
  speakers()
  dataImage()
  socialButtons()
  handleLongTitles()
  scheduleIcons()
  headerParallax()
  headerVideo()
  mailinglist()
  blocksCountdown()
  blocksSchedule()
  blocksMenuOrganisation()
  imageAttribution()
}
