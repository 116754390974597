/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'

const setTransform = function (el, transform) {
  const s = el[0].style
  s.webkitTransform = transform
  s.MozTransform = transform
  s.msTransform = transform
  s.OTransform = transform
  return (s.transform = transform)
}

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame

export default function () {
  $(function () {
    const $container = $('.header-container')
    const $head = $('.header-content')
    const $headBg = $('.header-background')
    const $headScrollIcon = $('.header-scroll-icon')

    let windowHeight = $(window).height()
    let opacity = 1
    let oldScrollPos = 0
    let haveScrolled = false

    $headScrollIcon.on('click', function (e) {
      e.preventDefault()
      e.stopPropagation()
      windowHeight = $(window).height()
      return $('html, body').animate(
        {
          scrollTop: `${windowHeight * 0.95}px`,
        },
        {
          duration: 600,
          easing: 'swing',
        }
      )
    })

    if (!requestAnimationFrame) {
      return
    }
    if ($head.length === 0 || $headBg.length === 0) {
      return
    }
    if ($(window).width() < 575) {
      return
    }
    if ($container.is('.header-cover-style-poster')) {
      return
    }

    var animationLoop = function () {
      const scrollPos = $(document).scrollTop()
      if (scrollPos < windowHeight && scrollPos !== oldScrollPos) {
        opacity = 1 - (scrollPos - 0.15 * windowHeight) / (0.85 * windowHeight)
        oldScrollPos = scrollPos
        const offset = scrollPos / 5
        setTransform($headBg, `translateY(${offset}px) translateZ(0)`)
      }

      if (!haveScrolled && scrollPos > windowHeight / 6) {
        haveScrolled = true
        $head.addClass('header-scrolled')
      }

      return requestAnimationFrame(animationLoop)
    }

    return animationLoop()
  })
}
