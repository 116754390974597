import { isIOS } from './utils'
import $ from 'jquery'

export default function () {
  $('.header-video, .video-uploaded').each(function (i, video) {
    const width = $(window).width()

    const urlHD = $(video).attr('data-src-hd')
    const urlSD = $(video).attr('data-src-sd')

    const url = width < 1000 && urlSD ? urlSD : urlHD

    const $videoContainer = $(this).parent()
    const $muteBtn = $videoContainer.find('.video-mute-button')
    const $playBtn = $videoContainer.find('.video-play-button')

    const setMute = function (mute) {
      if ($muteBtn.length === 0) return
      video.muted = mute
      if (mute) {
        $muteBtn.addClass('video-mute-button--muted')
      } else {
        $muteBtn.removeClass('video-mute-button--muted')
      }
    }

    $muteBtn.on('click', function () {
      setMute(!video.muted)
    })

    $playBtn.on('click', () => {
      if ($playBtn.hasClass('video-play-button--playing')) {
        video.pause()
      } else {
        video.play()
        if (!isIOS) {
          setMute(false)
        }
      }
      $playBtn.toggleClass('video-play-button--playing')
    })

    $(video).attr('src', url)

    if (video.autoplay) {
      let tries = 0
      const checkVideoAutoplay = () => {
        const promise = video.play()
        if (promise !== undefined) {
          return promise
            .then(function () {
              $('html').removeClass('no-videoautoplay')
              $('html').addClass('videoautoplay')
              return $muteBtn.removeClass('video-mute-button--hidden')
            })
            .catch(function () {
              $('html').removeClass('videoautoplay')
              $('html').addClass('no-videoautoplay')
              $muteBtn.addClass('video-mute-button--hidden')
              tries++
              if (tries < 10) setTimeout(checkVideoAutoplay, 500)
            })
        }
      }
      checkVideoAutoplay()
    }
  })
}
