/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery'

export default function () {
  $(() => {
    const addSpeakersReadMore = () =>
      $('.speakers-speaker-presentation .speaker-button').on('click', function () {
        let speakerTotalHeight = 0
        let speakerTotalLength = 0
        const speakerReadMoreButton = $(this)
        const speakerPresenter = speakerReadMoreButton.closest('div')
        const speakerReadMorePtag = speakerPresenter.find('.speaker-read-more')
        const $speakerBox = speakerPresenter.find('.speaker-speaker-content')
        const shade = speakerPresenter.find('.speaker-shade')
        const speakerBoxChild = $speakerBox.children()
        speakerBoxChild.each(function () {
          speakerTotalHeight += $(this).outerHeight()
          const speakerString = $(this).text()
          const speakerCount = speakerString.split(' ')
          const speakerResult = speakerCount.length
          return (speakerTotalLength += speakerResult)
        })

        const options = {
          duration: 1000,
          easing: 'swing',
        }
        $speakerBox
          .css({
            height: 'auto',
            'max-height': 200,
          })
          .animate(
            {
              height: 'auto',
              'max-height': 2000,
            },
            options,
          )
        speakerReadMorePtag.fadeOut(10)
        shade.fadeOut(10)
        return false
      })

    return $('.speakers-speaker').each(function () {
      const $speakerSpeaker = $(this)

      const forceReadMore = $speakerSpeaker.hasClass('speakers-speaker--force-read-more')

      const $speakerBox = $speakerSpeaker.find('.speaker-speaker-box')
      const $speakerRead = $speakerSpeaker.find('.speaker-read-more')
      const $speakerShade = $speakerBox.find('.speaker-shade')
      const speakerString = $speakerBox.text()
      const speakerCount = speakerString.split(' ')
      const speakerTotalLength = speakerCount.length
      if (!forceReadMore && speakerTotalLength < 226) {
        $(this)
        $speakerBox.css({
          height: 'auto',
          'max-height': 2000,
        })
        $speakerShade.css({ display: 'none' })
        return $speakerRead.css({ display: 'none' })
      } else {
        return addSpeakersReadMore()
      }
    })
  })
}
