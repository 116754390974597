// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.

window.isInIframe = function () {
  try {
    return window.self !== window.top
  } catch (error) {
    return true
  }
}
